<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">REGISTRATION </span> STATUS
    </PageHeader>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          class="secondary--text"
          v-if="
            getters_abilities.includes('course_registration_deadline_access')
          "
          :disabled="selected.length === 0"
          @click="applyChangesBtn"
        >
          Apply Changes
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        disable-pagination
        class="elevation-1"
        item-key="id"
        :loading="loadHeaders"
        show-select
        v-model="selected"
      >
        <template #item.status="{ item }">
          <v-switch
            v-model="item.status"
            :label="`${item.status ? 'Closed' : 'Open'}`"
            disabled
          >
            Closed
          </v-switch>
        </template>
      </v-data-table>
    </v-card>
    <v-card v-if="getters_current_headers.length" v-intersect="intersected">
      <v-progress-linear
        :indeterminate="true"
        v-if="loadingScroll"
      ></v-progress-linear>
    </v-card>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { getCurrentInstance, provide, reactive, toRefs } from "vue";
  export default {
    components: { PageHeader },
    setup() {
      const vm = getCurrentInstance();
      const { getCurrentHeaders, headerStatusChanges, signOut } = useActions([
        "getCurrentHeaders",
        "headerStatusChanges",
        "signOut",
      ]);
      const {
        getters_current_headers,
        getters_current_headers_meta,
        getters_abilities,
      } = useGetters([
        "getters_current_headers",
        "getters_current_headers_meta",
        "getters_abilities",
      ]);

      const table = reactive({
        headers: [
          { text: "ACADEMIC CALENDAR", value: "calendar.academic_calendar" },
          { text: "PROGRAM", value: "program.program_name_certificate" },
          { text: "LEVEL", value: "level.level" },
          { text: "INTAKE", value: "intake.intake" },
          { text: "SEMESTER", value: "semester.semester" },
          { text: "CAMPUS", value: "campus.campus_name" },
          { text: "STATUS", value: "status" },
        ],
        selected: [],
        loadHeaders: true,
        loadingScroll: false,
        isIntersecting: false,
        page: 1,
        items: [],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        levelItem: "",
        actionResponse: false,
        color: "",
        overlay: false,
      });

      const {
        overlay,
        loadHeaders,
        selected,
        isIntersecting,
        page,
        loadingScroll,
        items,
        actionResponse,
        msgHeader,
        msgBody,
        msgIcon,
        color,
      } = toRefs(table);

      getCurrentHeaders(page.value)
        .then(() => {
          loadHeaders.value = false;
          items.value = [...getters_current_headers.value];
        })
        .catch((e) => {
          color.value = "error";
          msgIcon.value = "mdi-close-circle";
          msgHeader.value = "Error";
          switch (e.response.status) {
            case 423:
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
              break;
            case 403:
              msgBody.value = e.response.data.message;
              break;
            default:
              msgBody.value = "Something Went Wrong. Try Again Later";
              break;
          }
        });

      const applyChangesBtn = () => {
        overlay.value = true;
        const basket = selected.value.map((row) => {
          let obj = {};
          obj = { id: row.id, status: row.status ? false : true };
          return obj;
        });
        headerStatusChanges(basket)
          .then(() => {
            selected.value = [];
          })
          .catch((e) => {
            color.value = "error";
            msgIcon.value = "mdi-close-circle";
            msgHeader.value = "Error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              case 403:
                msgBody.value = e.response.data.message;
                break;
              default:
                msgBody.value = "Something Went Wrong. Try Again Later";
                break;
            }
          })
          .finally(() => {
            actionResponse.value = false;
            overlay.value = false;
          });
      };

      const intersected = (entries) => {
        isIntersecting.value = entries[0].isIntersecting;

        if (!isIntersecting.value) {
          return;
        }
        loadingScroll.value = true;

        if (
          getters_current_headers_meta.value.current_page >=
          getters_current_headers_meta.value.last_page
        ) {
          loadingScroll.value = false;
          return;
        }

        page.value++;
        getCurrentHeaders(page.value).then(() => {
          loadingScroll.value = false;
          items.value = [
            ...new Set([...items.value, ...getters_current_headers.value]),
          ];
        });
      };

      provide("color", color);

      return {
        ...toRefs(table),
        items,
        applyChangesBtn,
        intersected,
        getters_current_headers,
        getters_abilities,
      };
    },
  };
</script>